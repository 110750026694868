
  <template>
  <div>
    <!-- Table Container Card -->
    <!-- <filters @filter="fetchDataHandler" /> -->

    <b-card no-body class="mb-0">
      <!-- Search -->
      <div
        class="
          m-2
          d-flex
          align-items-center
          justify-content-end
          flex-column flex-md-row
        "
      >
        <b-form-input
          v-model="searchQuery"
          class="search-input mr-md-1 mb-2 mb-md-0 w-auto"
          :placeholder="$t('search_by_name') || 'Search by name'"
          @input="searchHandler"
        />
        <AppButton
          v-if="userCan('gallery-add')"
          variant="primary"
          @click="openGalleryForm()"
        >
          <span class="text-nowrap">
            {{ $t("add_new") || "Add New" }}
            {{ $t("image") || "image" }}
          </span>
        </AppButton>
      </div>
      <b-overlay :show="isLoading" rounded>
        <b-table
          class="position-relative"
          :items="dataArray"
          hover
          responsive
          striped
          primary-key="id"
          :fields="fields"
          show-empty
          :empty-text="
            $t('no_matching_records_found') || 'No matching records found'
          "
        >
          <template #cell(checkbox)="data">
            <span class="align-text-top text-capitalize">
              <!-- data.item.can_edit_or_delete == '1' -->
              <b-form-checkbox
                :value="data.item.uuid"
                :checked="
                  selectedRows &&
                  selectedRows.length &&
                  selectedRows.find((e) => e === data.item.uuid)
                "
                class="align-text-top"
                @change="rowSelectedHandler($event, data.item.uuid)"
              ></b-form-checkbox>
            </span>
          </template>
          <template #cell(image)="data">
            <img
              v-if="data.item.path"
              data-test="image"
              height="100"
              width="100"
              class="object-fit-contain mb-2"
              :src="data.item.path"
              :alt="data.item.alt_ar || ''"
            />
          </template>
          <template #cell(name_en)="data">
            {{ data.item.name }}
          </template>
          <template #cell(name_ar)="data">
            {{ data.item.name_ar }}
          </template>
          <template #cell(edit_delete)="data">
            <div class="d-flex flex-column actions">
              <AppButton
                v-if="userCan('gallery-edit')"
                variant="primary"
                class="mb-1"
                @click="openGalleryForm(data.item)"
              >
                {{ $t("edit") || "Edit" }}
              </AppButton>
              <AppButton
                v-if="userCan('gallery-delete')"
                variant="danger"
                @click="openDeletionWarning(data.item)"
              >
                {{ $t("delete") || "Delete" }}
              </AppButton>
            </div>
          </template>
        </b-table>
        <div class="mx-2 my-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="
                d-flex
                align-items-center
                justify-content-center justify-content-sm-start
              "
            >
              <span v-if="dataArrayMeta" class="text-muted">
                {{ $t("total_entries", { total: dataArrayMeta.total }) }}
              </span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="
                d-flex
                align-items-center
                justify-content-center justify-content-sm-end
              "
            >
              <b-pagination
                v-if="dataArrayMeta && dataArrayMeta.total"
                v-model="dataArrayMeta.current_page"
                :total-rows="dataArrayMeta.total"
                :per-page="dataArrayMeta.per_page"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
                @change="changePaginationHandler"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-overlay>
    </b-card>
    <GalleryForm
      v-if="userCan('gallery-edit') || userCan('gallery-add')"
      :id="'gallery-form'"
      :initial-data="activeItem"
      @hide="hideGalleryForm"
    />

    <Warning
      id="delete-warning-modal"
      :is-loading="isDeleting"
      :payload="itemsToBeDeleted"
      @cancel="cancelDeletionHandler"
      @approve="deleteHandler"
    />
  </div>
</template>

<script>
import {
  BCard,
  BButton,
  BTable,
  BOverlay,
  BFormInput,
  BPagination,
  BRow,
  BCol,
  BFormCheckbox,
} from "bootstrap-vue";

// import GalleryForm from "./partials/gallery-form.vue";
import filters from "./partials/filters.vue";
import userCan from "@/mixins/UserCan";
import { AppButton } from "@/components/form/index";

import GalleryForm from "./partials/GalleryForm.vue";
// import CrudTable from "@/components/hoc/CrudTable.vue";
export default {
  name: "Gallery",
  components: {
    // CrudTable,
    GalleryForm,
    BRow,
    BFormCheckbox,
    BCol,
    BFormInput,
    BCard,
    BPagination,
    BButton,
    BTable,
    BOverlay,
    AppButton,
    filters,
  },
  data() {
    return {
      activeItem: null,
      selectedRows: [],
      itemsToBeDeleted: [],
      searchQuery: "",
      actions: [
        {
          label: this.$t("edit") || "Edit",
          variant: "primary",
          event: "edit",
        },
        {
          label: this.$t("delete") || "Delete",
          variant: "danger",
          event: "delete",
        },
      ],
      fields: [
        // {
        //   key: "checkbox",
        //   label: this.$t("select") || "Select",
        // },
        { key: "edit_delete", label: this.$t("edit_delete") || "Edit/Delete" },
        {
          key: "image",
          label: this.$t("image") || "image",
        },
        {
          key: "title_ar",
          label: this.$t("title_ar") || "Title in Arabic",
        },
      ],
      // isDeletionWarnShown: false,
    };
  },
  computed: {
    dataArray() {
      return this.$store.state.gallery.data;
    },
    isLoading() {
      return this.$store.state.gallery.isLoading.fetching;
    },
    isDeleting() {
      return this.$store.state.gallery.isLoading.deleting;
    },
    dataArrayMeta() {
      return this.$store.state.gallery.meta;
    },
  },
  created() {
    this.fetchgallery();
  },
  methods: {
    userCan,
    searchHandler(searchText) {
      // debounce
      clearTimeout(this.searchQueryTimer);
      this.searchQueryTimer = setTimeout(() => {
        const searchQuery = { name: searchText };
        this.$store.dispatch("gallery/fetchData", searchQuery);
      }, 300);
    },
    openGalleryForm(item = null) {
      if (item) this.activeItem = item;
      this.$nextTick(() => {
        this.$bvModal.show("gallery-form");
      });
    },
    hideGalleryForm() {
      this.activeItem = null;
    },
    rowSelectedHandler(state, uuid) {
      if (
        this.selectedRows &&
        this.selectedRows.length &&
        this.selectedRows.find((e) => e === uuid)
      ) {
        this.selectedRows = this.selectedRows.filter((e) => e !== uuid);
      } else {
        this.selectedRows.push(uuid);
      }
    },
    changePaginationHandler(page = 1) {
      this.fetchgallery({ page });
    },
    fetchgallery(params) {
      this.$store.dispatch("gallery/fetchData",params);
    },
    openDeletionWarning(item) {
      this.activeItem = item;
      this.$bvModal.show("delete-warning-modal");
    },
    cancelDeletionHandler() {
      this.activeItem = null;
      this.$bvModal.hide("delete-warning-modal");
    },
    deleteHandler() {
      this.$store
        .dispatch("gallery/deleteData", {
          uuid: this.activeItem?.uuid,
        })
        .then(() => {
          this.cancelDeletionHandler();
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.mi-end-2 {
  margin-inline-end: 1rem;
}
</style>
