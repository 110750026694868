<template>
  <AppForm
    :id="id"
    :ref="`${id}-form`"
    class="p-1"
    :title="
      initialData
        ? $t('edit_form', { name: initialData.name }) ||
          'Edit' + initialData.name
        : $t('add_new') || 'Add New'
    "
    :errors-bag="errorsBag"
    :success-message="successMessage"
    :is-loading="isLoading"
    :display-submit-and-add-new="false"
    @hide="hideForm"
    @submit.prevent="handleSubmit"
  >
    <AppFilePreview
      v-if="!initialData"
      v-model="formData.image"
      :value="formData.image"
      :label="$t('image')"
      name="image"
      multiple
      required
      rules="required"
      @remove="removeImageHandler"
      @changeFiles="formData.image = $event"
    />

    <AppInput
      v-model="formData.title_ar"
      type="text"
      required
      :label="$t('image_title') || 'image title'"
    />

    <AppInput
      v-model="formData.alt_ar"
      type="text"
      required
      :label="$t('image_alt') || 'image alt'"
    />

    <AppCheckboxes
      id="image_display_in_home_page"
      v-model="formData.in_home"
      :initialValue="initialData ? initialData.in_home : 0"
      textField="name"
      valueField="value"
      labelClass="my-0"
      :options="[
        {
          name: $t('display_in_home_page') || 'In Home',
          value: 1,
        },
      ]"
    />

    <AppCheckboxes
      id="image_display_in_gallery_page"
      v-model="formData.in_gallery"
      :initialValue="initialData ? initialData.in_gallery : 0"
      textField="name"
      valueField="value"
      labelClass="my-0"
      :options="[
        {
          name: $t('display_in_gallery_page') || 'in_gallery',
          value: 1,
        },
      ]"
    />
  </AppForm>
</template>

<script>
import {
  AppInput,
  AppForm,
  AppFilePreview,
  AppCheckboxes,
} from "@/components/form/index";

export default {
  name: "GalleryForm",
  components: {
    AppInput,
    AppFilePreview,
    AppForm,
    AppCheckboxes,
  },
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    initialData: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      formData: {
        image: null,
        title_ar: null,
        alt_ar: null,
        in_gallery: null,
        in_home: null,
      },
      successMessage: null,
    };
  },
  computed: {
    isLoading() {
      return (
        this.$store.state.gallery.isLoading.creating ||
        this.$store.state.gallery.isLoading.updating
      );
    },
    errorsBag() {
      return this.$store.state.gallery.errors;
    },
  },
  watch: {
    initialData: {
      handler(val) {
        if (!val) {
          this.formData = {
            image: null,
            title_ar: null,
            alt_ar: null,
            in_gallery: null,
            in_home: null,
          };
          return;
        }
        this.formData = {
          image: val.image || val.path,
          title_ar: val.title_ar,
          alt_ar: val.alt_ar,
          in_gallery: val.in_gallery,
          in_home: val.in_home,
          uuid: val.uuid,
        };
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    removeImageHandler() {
      this.formData.image = null;
    },
    handleSubmit() {
      const actionName = this.initialData ? "updateData" : "createData";

      const formData = new FormData();
      // formData.append("image", this.formData.image);
      !this.initialData &&
        formData.append("image", this.formData.image && this.formData.image[0]);
      formData.append("title_ar", this.formData.title_ar);
      formData.append("alt_ar", this.formData.alt_ar);
      formData.append("in_gallery", this.formData.in_gallery);
      formData.append("in_home", this.formData.in_home);

      this.initialData && formData.append("uuid", this.initialData.uuid);

      this.$store.dispatch(`gallery/${actionName}`, formData).then((res) => {
        this.successMessage =
          this.$t("image_has_been_saved") || "image has been saved";
        setTimeout(() => {
          this.successMessage = null;
        }, 1000);
        this.$emit("uploaded", res?.data);
        this.hideForm();
      });
    },
    hideForm() {
      this.$bvModal.hide(this.id);
      this.$store.dispatch("gallery/resetModule");
      this.formData = {
        name_ar: null,
      };
      this.$emit("hide");
      this.formData = {
        image: null,
        title_ar: null,
        alt_ar: null,
        in_gallery: null,
        in_home: null,
      };
    },
  },
};
</script >
