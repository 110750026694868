var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppForm',{ref:(_vm.id + "-form"),staticClass:"p-1",attrs:{"id":_vm.id,"title":_vm.initialData
      ? _vm.$t('edit_form', { name: _vm.initialData.name }) ||
        'Edit' + _vm.initialData.name
      : _vm.$t('add_new') || 'Add New',"errors-bag":_vm.errorsBag,"success-message":_vm.successMessage,"is-loading":_vm.isLoading,"display-submit-and-add-new":false},on:{"hide":_vm.hideForm,"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[(!_vm.initialData)?_c('AppFilePreview',{attrs:{"value":_vm.formData.image,"label":_vm.$t('image'),"name":"image","multiple":"","required":"","rules":"required"},on:{"remove":_vm.removeImageHandler,"changeFiles":function($event){_vm.formData.image = $event}},model:{value:(_vm.formData.image),callback:function ($$v) {_vm.$set(_vm.formData, "image", $$v)},expression:"formData.image"}}):_vm._e(),_c('AppInput',{attrs:{"type":"text","required":"","label":_vm.$t('image_title') || 'image title'},model:{value:(_vm.formData.title_ar),callback:function ($$v) {_vm.$set(_vm.formData, "title_ar", $$v)},expression:"formData.title_ar"}}),_c('AppInput',{attrs:{"type":"text","required":"","label":_vm.$t('image_alt') || 'image alt'},model:{value:(_vm.formData.alt_ar),callback:function ($$v) {_vm.$set(_vm.formData, "alt_ar", $$v)},expression:"formData.alt_ar"}}),_c('AppCheckboxes',{attrs:{"id":"image_display_in_home_page","initialValue":_vm.initialData ? _vm.initialData.in_home : 0,"textField":"name","valueField":"value","labelClass":"my-0","options":[
      {
        name: _vm.$t('display_in_home_page') || 'In Home',
        value: 1,
      } ]},model:{value:(_vm.formData.in_home),callback:function ($$v) {_vm.$set(_vm.formData, "in_home", $$v)},expression:"formData.in_home"}}),_c('AppCheckboxes',{attrs:{"id":"image_display_in_gallery_page","initialValue":_vm.initialData ? _vm.initialData.in_gallery : 0,"textField":"name","valueField":"value","labelClass":"my-0","options":[
      {
        name: _vm.$t('display_in_gallery_page') || 'in_gallery',
        value: 1,
      } ]},model:{value:(_vm.formData.in_gallery),callback:function ($$v) {_vm.$set(_vm.formData, "in_gallery", $$v)},expression:"formData.in_gallery"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }